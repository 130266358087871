.accordion :global .MuiAccordionDetails-root > div > div:first-child,
.accordion :global .MuiAccordionSummary-expandIconWrapper {
  display: none;
}

.accordion {
  border: 0;
  height: 100%;
  border-color: var(--color-border-light) !important ;
}

.accordion :global .MuiAccordionSummary-root {
  background: transparent !important;
  padding: var(--space-3);
  border-bottom: 1px solid var(--color-border-light);
  pointer-events: none;
}

.accordion :global .MuiAccordionSummary-content {
  margin: 0;
}

.sidebar :global .MuiPaper-root {
  height: calc(100vh - var(--header-height) - var(--footer-height) - 24px);
  overflow: auto;
}

.content {
  background: linear-gradient(-150deg, #ffe9e7, #6aaeff);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  border-radius: 6px;
  padding: var(--space-8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.logo {
  height: 24px;
}

.visit-button {
  @apply w-[107px] h-[40px];
  border-radius: 8px !important;
  background: var(--m-3-sys-light-primary, #0060a9) !important;
  color: var(--m-3-sys-light-on-primary, #fff) !important ;
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
  width: 161px;
}

.later-button {
  @apply w-[57px] h-[40px] !mr-4;
  color: var(--m-3-ref-neutral-variant-neutral-variant-20, #2c3137) !important;
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
}

.hints-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 40px;
  bottom: -10px;
  width: 393px;
  padding: 24px;
  border-radius: 4px;
  background: var(--m-3-sys-light-background, #fdfcff);
  box-shadow:
    0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  gap: 16px;

}

.hints-title {
  @apply mb-2;
  color: var(--m-3-ref-neutral-variant-neutral-variant-20, #2c3137);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.hints-description {
  @apply mb-4;
  color: var(--m-3-ref-neutral-variant-neutral-variant-60, #8d9199);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

@media (max-width: 900px) {
  .sidebar :global .MuiPaper-root {
    height: 100%;
  }

  .accordion {
    height: auto;
  }

  .accordion :global .MuiAccordionSummary-root {
    pointer-events: auto;
  }

  .accordion :global .MuiAccordionSummary-expandIconWrapper {
    display: block;
  }

  .content {
    padding: var(--space-6);
  }
}

@media (max-width: 600px) {
  .content {
    padding: var(--space-4);
  }

  .hints-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    transform: translateX(-50%);
    gap: 16px;
  }

  .logo {
    height: 40px;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
